exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-cdap-js": () => import("./../../../src/templates/cdap.js" /* webpackChunkName: "component---src-templates-cdap-js" */),
  "component---src-templates-connect-js": () => import("./../../../src/templates/connect.js" /* webpackChunkName: "component---src-templates-connect-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/defaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-industries-js": () => import("./../../../src/templates/industries.js" /* webpackChunkName: "component---src-templates-industries-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-roi-tool-js": () => import("./../../../src/templates/roiTool.js" /* webpackChunkName: "component---src-templates-roi-tool-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

